import axios, { AxiosError } from 'axios';

const upload = async (formData: any, uploadUrl: string, config?: any) => {
  try {
    const resp = await axios.put(uploadUrl, formData, config);
    return resp.status >= 200 && resp.status < 300;
  } catch (err) {
    const failedResponse = err as Error | AxiosError;
    if (axios.isAxiosError(failedResponse) && failedResponse.response) {
      console.error(
        `Upload request to URL ${uploadUrl} with formdata ${JSON.stringify(formData)} failed with status ${
          failedResponse.response.status
        } and response ${JSON.stringify(failedResponse.response)}`
      );
    } else {
      console.error('Unable to parse upload response from axios PUT request');
    }

    throw new Error(`Unable to complete upload request, failed with error ${err}`);
  }
};

export { upload };
